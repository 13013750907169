<template>
  <v-container fluid style='height: 85vh'>
    <v-card flat class='wd-100'>
      <PeriodPickerToolbar @load-data='getRecordsNotFound' />
      <RecordReusageNotFoundTable :not-found-list='notFoundList' />
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'RecordReusageNotFound',
    components: {
      PeriodPickerToolbar: () => import('../../components/PeriodPickerToolbar/PeriodPickerToolbar'),
      RecordReusageNotFoundTable: () => import('./RecordReusageNotFoundTable'),
    },
    data: function () {
      return {
        notFoundList: [],
      };
    },
    mounted: function () {
      this.getRecordsNotFound();
    },
    methods: {
      getRecordsNotFound: async function (date) {
        try {
          const { data } = await axios({
            url: '/record-reusage/not-found',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            params: {
              initialDate: date[0],
              endDate: date[1],
            },
          });

          this.notFoundList = data.recordsNotFound;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
    },
  };
</script>
